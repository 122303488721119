import React from "react";
import Resume from "../Resume.pdf";

const SignInPage = () => {
  return (
    <a href={Resume} target="_blank">
      Resume
    </a>
  );
};

export default SignInPage;
