import React, { useState } from "react";
import Video from "../../videos/river.mp4";
import Video2 from "../../videos/rainlaptop.mp4";
import {
  HeroBg,
  HeroContainer,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { Button } from "../ButtonElement";
import Resume from "../../Resume.pdf";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const onResumeClick = () => {
    window.open(Resume);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video2} type="rainlaptop/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Hi, I'm Faisal.</HeroH1>
        <HeroP>
          Salam and welcome to my experimentation site. Built from scratch. This
          place goes through iterations of whatever I'm feeling at the moment.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            onClick={onResumeClick}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary={true}
            dark={true}
          >
            Resume {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
